function View(element, calendar, viewName) {
	var t = this;

	// exports
	t.element = element;
	t.calendar = calendar;
	t.name = viewName;
	t.opt = opt;
	t.trigger = trigger;
	t.isPreventEventMovement = isPreventEventMovement;
	t.isEventDraggable = isEventDraggable;
	t.isEventResizable = isEventResizable;
	t.clearEventData = clearEventData;
	t.reportEventElement = reportEventElement;
	t.triggerEventDestroy = triggerEventDestroy;
	t.eventElementHandlers = eventElementHandlers;
	t.showEvents = showEvents;
	t.hideEvents = hideEvents;
	t.eventDrop = eventDrop;
	t.eventCancelDrop = eventCancelDrop;
	t.eventResize = eventResize;
	t.manageClone = manageClone;
	t.duplicateEvent = duplicateEvent;
	t.createExternalDragTimeDates = createExternalDragTimeDates;
	t.createExternalDragDayDates = createExternalDragDayDates;
	// t.start, t.end // moments with ambiguous-time
	// t.intervalStart, t.intervalEnd // moments with ambiguous-time

	// imports
	var reportEventChange = calendar.reportEventChange;

	// locals
	var eventElementsByID = {}; // eventID mapped to array of jQuery elements
	var eventElementCouples = []; // array of objects, { event, element } // TODO: unify with segment system
	var options = calendar.options;
	var nextDayThreshold = moment.duration(options.nextDayThreshold);

	function opt(name, viewNameOverride, retainObjectFormat) {
		var v = options[name];
		if (
			$.isPlainObject(v) &&
			!isForcedAtomicOption(name) &&
			!retainObjectFormat
		) {
			return smartProperty(v, viewNameOverride || viewName);
		}
		return v;
	}

	function trigger(name, thisObj) {
		return calendar.trigger.apply(
			calendar,
			[name, thisObj || t].concat(
				Array.prototype.slice.call(arguments, 2),
				[t]
			)
		);
	}

	/* Event Editable Boolean Calculations
	------------------------------------------------------------------------------*/
	function isPreventEventMovement() {
		if (options.status && options.status.preventDrag) {
			return true;
		}
	}

	function isEventDraggable(event) {
		var eventMapped = !event.eventID ? false : true;
		var source = event.source || {};

		if (event.isUnavailable) {
			return false;
		}

		return firstFalse(
			eventMapped,
			event.startEditable,
			source.startEditable,
			opt('eventStartEditable'),
			event.editable,
			source.editable,
			opt('editable')
		);
	}

	function isEventResizable(event) {
		// but also need to make sure the seg.isEnd == true
		var eventMapped = !event.eventID ? false : true;
		var source = event.source || {};

		if (event.isUnavailable) {
			return false;
		}

		return firstFalse(
			eventMapped,
			event.durationEditable,
			source.durationEditable,
			opt('eventDurationEditable'),
			event.editable,
			source.editable,
			opt('editable')
		);
	}

	/* Event Data
	------------------------------------------------------------------------------*/

	function clearEventData() {
		eventElementsByID = {};
		eventElementCouples = [];
	}

	/* Event Elements
	------------------------------------------------------------------------------*/

	// report when view creates an element for an event
	function reportEventElement(event, element) {
		eventElementCouples.push({event: event, element: element});
		if (eventElementsByID[event._id]) {
			eventElementsByID[event._id].push(element);
		} else {
			eventElementsByID[event._id] = [element];
		}
	}

	function triggerEventDestroy() {
		$.each(eventElementCouples, function (i, couple) {
			t.trigger(
				'eventDestroy',
				couple.event,
				couple.event,
				couple.element
			);
		});
	}

	// attaches eventClick, eventMouseover, eventMouseout
	function eventElementHandlers(event, eventElement) {
		eventElement
			.click(function (ev) {
				if (
					!eventElement.hasClass('ui-draggable-dragging') &&
					!eventElement.hasClass('ui-resizable-resizing')
				) {
					//Customized for SeedCode. We don't want to just assume that a clicked link should navigate to that link.
					// Turned off because we now allow links and other html in title display
					//ev.preventDefault();
					//--------------------

					// Event click is a promise now (Only event click as it's shared with unscheduled and needs to know details from the popover)
					trigger('eventClick', this, event, ev)
						.then(() => {
							// Could do something when resolved
						})
						.catch(() => {
							// Could do something when rejected
						});
					return;
				}
			})
			//Customized for seedcode double click
			.dblclick(function (ev) {
				//Customized for SeedCode. We don't want to just assume that a clicked link should navigate to that link.
				ev.preventDefault();
				return trigger('eventDblClick', this, event, ev);
			})
			.hover(
				function (ev) {
					trigger('eventMouseover', this, event, ev);
				},
				function (ev) {
					trigger('eventMouseout', this, event, ev);
				}
			);
		// TODO: don't fire eventMouseover/eventMouseout *while* dragging is occuring (on subject element)
		// TODO: same for resizing
	}

	function showEvents(event, exceptElement) {
		eachEventElement(event, exceptElement, 'show');
	}

	function hideEvents(event, exceptElement) {
		eachEventElement(event, exceptElement, 'hide');
	}

	function eachEventElement(event, exceptElement, funcName) {
		// NOTE: there may be multiple events per ID (repeating events)
		// and multiple segments per event
		var elements = eventElementsByID[event._id],
			i,
			len = elements.length;
		for (i = 0; i < len; i++) {
			if (!exceptElement || elements[i][0] != exceptElement[0]) {
				elements[i][funcName]();
			}
		}
	}

	/* Event Modification Reporting
	---------------------------------------------------------------------------------*/
	function manageClone(ev, ui, event) {
		var cloneElement = ui.helper;

		if (!reportModifyerKey(ev)) {
			cloneElement.css('opacity', 1);
			cloneElement.addClass('no-clone');
			return false;
		} else {
			cloneElement.css('opacity', 0.5);
			cloneElement.removeClass('no-clone');
			return true;
		}
	}

	function reportModifyerKey(ev) {
		if (navigator.platform.indexOf('Mac') > -1) {
			return ev.altKey;
		} else {
			return ev.ctrlKey;
		}
		//windows
		// navigator.platform.indexOf('Win') > -1
	}
	function duplicateEvent(event, dateStart, dateEnd) {
		// Optional date start and date end values to overwrite what's in the event
		var outputEvent = JSON.parse(JSON.stringify(event));
		//Reset complex objects as they may not have made it through the stringify process
		outputEvent.start = dateStart ? dateStart.clone() : event.start.clone();
		outputEvent.end = dateEnd ? dateEnd.clone() : event.end.clone();
		outputEvent._start = dateStart
			? dateStart.clone()
			: event._start.clone();
		outputEvent._end = dateEnd ? dateEnd.clone() : event._end.clone();
		outputEvent.schedule = event.schedule;

		return outputEvent;
	}

	function eventDrop(
		el,
		event,
		newStart,
		ev,
		ui,
		breakoutData,
		isClone,
		isAltView
	) {
		var undoMutation;
		var outputEvent = isClone ? duplicateEvent(event) : event;

		undoMutation = calendar.mutateEvent(
			outputEvent,
			newStart,
			null,
			breakoutData && breakoutData.field === 'schedule'
				? null
				: breakoutData,
			isClone
		);

		trigger(
			'eventDrop',
			el,
			outputEvent,
			function (callback) {
				if (undoMutation) {
					undoMutation();
				}
				if (callback) {
					callback();
				}
				reportEventChange(event._id);
			},
			ev,
			ui,
			breakoutData,
			isClone,
			isAltView
		);

		if (!isClone) {
			reportEventChange(event._id);
		}
	}

	function eventCancelDrop(el, event, ev, ui, breakoutData) {
		// breakoutData {field: someField, value: someValue}
		trigger('eventCancelDrop', el, event, ev, ui, breakoutData);
	}

	function eventResize(el, event, newEnd, ev, ui, newStart) {
		var undoMutation = calendar.mutateEvent(event, newStart, newEnd);

		trigger(
			'eventResize',
			el,
			event,
			function () {
				undoMutation();
				reportEventChange(event._id);
			},
			ev,
			ui
		);

		reportEventChange(event._id);
	}

	// ====================================================================================================
	// Utilities for day "cells"
	// ====================================================================================================
	// The "basic" views are completely made up of day cells.
	// The "agenda" views have day cells at the top "all day" slot.
	// This was the obvious common place to put these utilities, but they should be abstracted out into
	// a more meaningful class (like DayEventRenderer).
	// ====================================================================================================

	// For determining how a given "cell" translates into a "date":
	//
	// 1. Convert the "cell" (row and column) into a "cell offset" (the # of the cell, cronologically from the first).
	//    Keep in mind that column indices are inverted with isRTL. This is taken into account.
	//
	// 2. Convert the "cell offset" to a "day offset" (the # of days since the first visible day in the view).
	//
	// 3. Convert the "day offset" into a "date" (a Moment).
	//
	// The reverse transformation happens when transforming a date into a cell.

	// exports
	t.isHiddenDay = isHiddenDay;
	t.getFirstDayShown = getFirstDayShown;
	t.skipHiddenDays = skipHiddenDays;
	t.getCellsPerWeek = getCellsPerWeek;
	t.dateToRow = dateToRow;
	t.dateToCell = dateToCell;
	t.dateToDayOffset = dateToDayOffset;
	t.dateToTimeOffset = dateToTimeOffset;
	t.dateToRowTimeOffset = dateToRowTimeOffset;
	t.dayOffsetToCellOffset = dayOffsetToCellOffset;
	t.cellOffsetToCell = cellOffsetToCell;
	t.rowToDate = rowToDate;
	t.cellToDate = cellToDate;
	t.cellToTime = cellToTime;
	t.cellToRowTime = cellToRowTime;
	t.cellToCellOffset = cellToCellOffset;
	t.cellOffsetToDayOffset = cellOffsetToDayOffset;
	t.resourceCellOffsetToDayOffset = resourceCellOffsetToDayOffset;
	t.dayOffsetToDate = dayOffsetToDate;
	t.rangeToSegments = rangeToSegments;

	//Customized for SeedCode
	t.resourceGridRangeToSegments = resourceGridRangeToSegments;
	t.resourceAgendaGridRangeToSegments = resourceAgendaGridRangeToSegments;
	t.resourceRangeToSegments = resourceRangeToSegments;
	t.resourceDaysRangeToSegments = resourceDaysRangeToSegments;
	t.agendaDaysRangeToSegments = agendaDaysRangeToSegments;
	t.resourceSkipHiddenDays = resourceSkipHiddenDays;
	t.getHiddenDayOffset = getHiddenDayOffset;

	// internals
	var hiddenDays = opt('hiddenDays') || []; // array of day-of-week indices that are hidden
	var isHiddenDayHash = []; // is the day-of-week hidden? (hash with day-of-week-index -> bool)
	var cellsPerWeek;
	var dayToCellMap = []; // hash from dayIndex -> cellIndex, for one week
	var cellToDayMap = []; // hash from cellIndex -> dayIndex, for one week
	var isRTL = opt('isRTL');

	//Customized for SeedCode
	var resourceDays = Number(opt('resourceDays')); // Options come in as a string. So make sure we convert to number

	// initialize important internal variables
	(function () {
		if (opt('weekends') === false) {
			hiddenDays.push(0, 6); // 0=sunday, 6=saturday
		}

		// Loop through a hypothetical week and determine which
		// days-of-week are hidden. Record in both hashes (one is the reverse of the other).
		for (var dayIndex = 0, cellIndex = 0; dayIndex < 7; dayIndex++) {
			dayToCellMap[dayIndex] = cellIndex;
			isHiddenDayHash[dayIndex] = $.inArray(dayIndex, hiddenDays) != -1;
			if (!isHiddenDayHash[dayIndex]) {
				cellToDayMap[cellIndex] = dayIndex;
				cellIndex++;
			}
		}

		cellsPerWeek = cellIndex;
		if (!cellsPerWeek) {
			throw 'invalid hiddenDays'; // all days were hidden? bad.
		}
	})();

	// Is the current day hidden?
	// `day` is a day-of-week index (0-6), or a Moment
	function isHiddenDay(day) {
		if (moment.isMoment(day)) {
			day = day.day();
		}
		return isHiddenDayHash[day];
	}

	function getFirstDayShown(day) {
		while (isHiddenDayHash[(day + 7) % 7]) {
			day++;
		}
		return day;
	}

	function getCellsPerWeek() {
		return cellsPerWeek;
	}

	// Incrementing the current day until it is no longer a hidden day, returning a copy.
	// If the initial value of `date` is not a hidden day, don't do anything.
	// Pass `isExclusive` as `true` if you are dealing with an end date.
	// `inc` defaults to `1` (increment one day forward each time)
	function skipHiddenDays(date, inc, isExclusive) {
		var out = date.clone();
		inc = inc || 1;
		while (isHiddenDayHash[(out.day() + (isExclusive ? inc : 0) + 7) % 7]) {
			out.add(inc, 'days');
		}
		return out;
	}

	function resourceSkipHiddenDays(dateStart, dateEnd, inc, isExclusive) {
		var out = dateEnd.clone();
		var difference = Math.abs(dateEnd.diff(dateStart, 'days'));
		var startDay = dateStart.day();
		var day;
		var hiddenDayCount = 0;
		var i = 0;
		difference = isExclusive ? difference + 1 : difference;
		inc = inc || 1;

		while (i < difference) {
			// for (var i = 0; i <= difference; i++ ) {
			if (inc < 0) {
				day = (startDay - i + 7 * Math.ceil(i / 7)) % 7;
			} else {
				day = (startDay + i) % 7;
			}
			if (isHiddenDayHash[day]) {
				difference++;
				hiddenDayCount++;
			}
			i++;
		}
		return dateEnd.add(hiddenDayCount * inc, 'days');
	}

	//
	// TRANSFORMATIONS: cell -> cell offset -> day offset -> date
	//

	function rowToDate(row) {
		// var rowCnt = t.getRowCnt();
		var dayOffset = cellOffsetToDayOffset(row);
		var date = dayOffsetToDate(dayOffset);
		return date;
	}

	// cell -> date (combines all transformations)
	// Possible arguments:
	// - row, col
	// - { row:#, col: # }
	function cellToDate() {
		var cellOffset = cellToCellOffset.apply(null, arguments);
		var dayOffset = cellOffsetToDayOffset(cellOffset);
		var date = dayOffsetToDate(dayOffset);
		return date;
	}

	// cell -> time (combines all transformations)
	// Possible arguments:
	// - row, col
	// - { row:#, col: # }
	function cellToTime() {
		var cellOffset = cellToCellOffset.apply(null, arguments);
		var startTime = t.start;
		var gridTimes = options.gridTimes;
		var date = calendar.unzoneDate(startTime);
		//Mark date as having a time
		delete date._ambigTime;
		return calendar.rezoneDate(
			date.add(moment.duration(gridTimes[cellOffset]))
		);
	}

	// cell -> time (combines all transformations)
	// Possible arguments:
	// - row, col
	// - { row:#, col: # }
	function cellToRowTime() {
		var row = arguments[0].row;
		var col = arguments[0].col;
		var startTime = t.start;
		var cellOffsetArguments = {row: 0, col: col};
		var cellOffset = cellToCellOffset(cellOffsetArguments);
		var gridTimes = options.gridTimes;
		var date = calendar.unzoneDate(startTime);
		var dayDelta = cellOffsetToDayOffset(row);
		//Mark date as having a time
		delete date._ambigTime;
		date.add(dayDelta, 'days');
		return calendar.rezoneDate(
			date.add(moment.duration(gridTimes[cellOffset]))
		);
	}

	// cell -> date (combines all transformations)
	// Possible arguments:
	// - row, col
	// - { row:#, col: # }
	function resourceCellToDate() {
		var cellOffset = cellToCellOffset.apply(null, arguments);
		var resourceDayOffset = cellOffsetToDayOffset(cellOffset);
		var date = dayOffsetToDate(dayOffset);
		return date;
	}

	// cell offset -> day offset
	function resourceCellOffsetToDayOffset(cellOffset) {
		var day0 = t.start.day(); // first date's day of week
		cellOffset += dayToCellMap[day0]; // normlize cellOffset to beginning-of-week
		return (
			Math.floor(cellOffset / cellsPerWeek) * 7 + // # of days from full weeks
			cellToDayMap[ // # of days from partial last week
				((cellOffset % cellsPerWeek) + cellsPerWeek) % cellsPerWeek // crazy math to handle negative cellOffsets
			] -
			day0
		); // adjustment for beginning-of-week normalization
	}

	// cell -> cell offset
	// Possible arguments:
	// - row, col
	// - { row:#, col:# }
	function cellToCellOffset(row, col) {
		var colCnt = t.getColCnt();

		// rtl variables. wish we could pre-populate these. but where?
		var dis = isRTL ? -1 : 1;
		var dit = isRTL ? colCnt - 1 : 0;

		if (typeof row == 'object') {
			col = row.col;
			row = row.row;
		}
		var cellOffset = row * colCnt + (col * dis + dit); // column, adjusted for RTL (dis & dit)
		return cellOffset;
	}

	// cell offset -> day offset
	function cellOffsetToDayOffset(cellOffset) {
		var day0 = t.start.day(); // first date's day of week
		cellOffset += dayToCellMap[day0]; // normlize cellOffset to beginning-of-week

		return (
			Math.floor(cellOffset / cellsPerWeek) * 7 + // # of days from full weeks
			cellToDayMap[ // # of days from partial last week
				((cellOffset % cellsPerWeek) + cellsPerWeek) % cellsPerWeek // crazy math to handle negative cellOffsets
			] -
			day0
		); // adjustment for beginning-of-week normalization
	}

	// day offset -> date
	function dayOffsetToDate(dayOffset) {
		return t.start.clone().add(dayOffset, 'days');
	}

	//
	// TRANSFORMATIONS: date -> day offset -> cell offset -> cell
	//

	// date -> cell (combines all transformations)
	function dateToRow(date) {
		var dayOffset = dateToDayOffset(date);
		return dayOffset;
	}

	// date -> cell (combines all transformations)
	function dateToCell(date) {
		var dayOffset = dateToDayOffset(date);
		var cellOffset = dayOffsetToCellOffset(dayOffset);
		var cell = cellOffsetToCell(cellOffset);
		return cell;
	}

	// date -> day offset
	function dateToDayOffset(date) {
		return date.clone().stripTime().diff(t.start, 'days');
	}

	// date -> time offset
	function dateToTimeOffset(date, minuteIncrement) {
		var gridTime = moment.duration(options.gridTimes[0]);
		var utcDate = calendar.unzoneDate(date); //Convert to utc so our math ignores DST. DST fix.
		var utcStart = calendar.unzoneDate(t.start); //Convert to utc so our math ignores DST. DST fix.
		return Math.floor(
			utcDate.diff(utcStart.add(gridTime), 'minutes') / minuteIncrement
		);
	}

	// date -> time row offset
	function dateToRowTimeOffset(date, row, minuteIncrement, useEnd) {
		var colCnt = t.getColCnt();
		var gridTime = moment.duration(options.gridTimes[0]);
		var utcDate = calendar.unzoneDate(date); //Convert to utc so our math ignores DST. DST fix.
		var utcCompareDate = calendar.unzoneDate(
			(useEnd ? t.end : t.start).clone().add(row, 'days')
		); //Convert to utc so our math ignores DST. DST fix.
		return Math.floor(
			utcDate.diff(utcCompareDate.add(gridTime), 'minutes') /
				minuteIncrement +
				row * colCnt
		);
	}

	// day offset -> cell offset
	function dayOffsetToCellOffset(dayOffset) {
		var day0 = t.start.day(); // first date's day of week
		dayOffset += day0; // normalize dayOffset to beginning-of-week
		return (
			Math.floor(dayOffset / 7) * cellsPerWeek + // # of cells from full weeks
			dayToCellMap[ // # of cells from partial last week
				((dayOffset % 7) + 7) % 7 // crazy math to handle negative dayOffsets
			] -
			dayToCellMap[day0]
		); // adjustment for beginning-of-week normalization
	}

	// cell offset -> cell (object with row & col keys)
	function cellOffsetToCell(cellOffset) {
		var colCnt = t.getColCnt();

		// rtl variables. wish we could pre-populate these. but where?
		var dis = isRTL ? -1 : 1;
		var dit = isRTL ? colCnt - 1 : 0;

		var row = Math.floor(cellOffset / colCnt);
		var col = (((cellOffset % colCnt) + colCnt) % colCnt) * dis + dit; // column, adjusted for RTL (dis & dit)
		return {
			row: row,
			col: col,
		};
	}

	//
	// Converts a date range into an array of segment objects.
	// "Segments" are horizontal stretches of time, sliced up by row.
	// A segment object has the following properties:
	// - row
	// - cols
	// - isStart
	// - isEnd
	//
	//Customized for SeedCode so we can use resources
	function rangeToSegments(start, end, isResourceView, resource) {
		var rowCnt = t.getRowCnt();
		var resourceDays = options.resourceDays;
		var colCnt = t.getColCnt();
		var instance = 0;
		var resources;
		if (t.getResources) {
			resources = t.getResources(false);
		}

		var segments = []; // array of segments to return
		var cols = [];

		// day offset for given date range (how many days from our view start date)
		var rangeDayOffsetStart = dateToDayOffset(start);
		var rangeDayOffsetEnd = dateToDayOffset(end); // an exclusive value
		var endTimeMS = +end.time();
		if (endTimeMS && endTimeMS >= nextDayThreshold) {
			rangeDayOffsetEnd++;
		}
		rangeDayOffsetEnd = Math.max(
			rangeDayOffsetEnd,
			rangeDayOffsetStart + 1
		);

		// first and last cell offset for the given date range
		// "last" implies inclusivity
		var rangeCellOffsetFirst = dayOffsetToCellOffset(rangeDayOffsetStart);
		var rangeCellOffsetLast = dayOffsetToCellOffset(rangeDayOffsetEnd) - 1;

		// loop through all the rows in the view
		for (var row = 0; row < rowCnt; row++) {
			// first and last cell offset for the row
			var rowCellOffsetFirst = row * colCnt;
			var rowCellOffsetLast = rowCellOffsetFirst + colCnt - 1;

			// get the segment's cell offsets by constraining the range's cell offsets to the bounds of the row
			var segmentCellOffsetFirst = Math.max(
				rangeCellOffsetFirst,
				rowCellOffsetFirst
			);
			var segmentCellOffsetLast = Math.min(
				rangeCellOffsetLast,
				rowCellOffsetLast
			);

			// make sure segment's offsets are valid and in view
			if (segmentCellOffsetFirst <= segmentCellOffsetLast) {
				// translate to cells
				var segmentCellFirst = cellOffsetToCell(segmentCellOffsetFirst);
				var segmentCellLast = cellOffsetToCell(segmentCellOffsetLast);

				// Determine if segment's first/last cell is the beginning/end of the date range.
				// We need to compare "day offset" because "cell offsets" are often ambiguous and
				// can translate to multiple days, and an edge case reveals itself when we the
				// range's first cell is hidden (we don't want isStart to be true).
				var isStart =
					cellOffsetToDayOffset(segmentCellOffsetFirst) ==
					rangeDayOffsetStart;
				var isEnd =
					cellOffsetToDayOffset(segmentCellOffsetLast) + 1 ==
					rangeDayOffsetEnd; // +1 for comparing exclusively

				// view might be RTL, so order by leftmost column
				// cols = [segmentCellFirst.col, segmentCellLast.col].sort();
				cols = [segmentCellFirst.col, segmentCellLast.col]; //Removed sort because it screws up when we have large numbers

				segments.push({
					row: row,
					leftCol: cols[0],
					rightCol: cols[1],
					isStart: isStart,
					isEnd: isEnd,
					instance: instance,
				});

				instance++;
			}
		}
		return segments;
	}

	//Customized for SeedCode so we can use resource rows rather than dates / times
	function resourceGridRangeToSegments(
		start,
		end,
		breakoutItems,
		breakoutItem,
		noResources,
		noBreakout,
		clusterOptions
	) {
		var rowCnt = t.getRowCnt();
		var colCnt = t.getColCnt();
		var noFilterLabel = t.opt('noFilterLabel');
		var instance = 0;
		if (!breakoutItems && t.getResources) {
			breakoutItems = t.getResources(false);
		}
		if (Array.isArray(breakoutItem) && !breakoutItem.length) {
			breakoutItem = [''];
		} else if (
			typeof breakoutItem === 'object' &&
			breakoutItem !== null &&
			breakoutItem.name
		) {
			breakoutItem = [breakoutItem.name];
		} else if (!breakoutItem || !Array.isArray(breakoutItem)) {
			// Convert booleans to string as that's what will be compared
			if (breakoutItem === true) {
				breakoutItem = breakoutItem.toString();
			} else if (breakoutItem === false) {
				// false is set to empty so it goes in the "none" category
				breakoutItem = '';
			}
			breakoutItem = [breakoutItem];
		}

		var segments = []; // array of segments to return
		var cols = [];

		// day offset for given date range (how many days from our view start date)
		var rangeDayOffsetStart = dateToDayOffset(start);
		var rangeDayOffsetEnd = dateToDayOffset(end); // an exclusive value

		var endTimeMS = +end.time();
		if (endTimeMS && endTimeMS >= nextDayThreshold) {
			rangeDayOffsetEnd++;
		}
		rangeDayOffsetEnd = Math.max(
			rangeDayOffsetEnd,
			rangeDayOffsetStart + 1
		);

		// first and last cell offset for the given date range
		// "last" implies inclusivity
		var rangeCellOffsetFirst = dayOffsetToCellOffset(rangeDayOffsetStart);
		var rangeCellOffsetLast = dayOffsetToCellOffset(rangeDayOffsetEnd) - 1;

		if (clusterOptions) {
			var clusteringOffsetFirst = Math.floor(
				start.diff(t.start, clusterOptions.type, true) /
					clusterOptions.typeOffset
			);
			var clusteringOffsetLast =
				Math.ceil(
					end.diff(t.start, clusterOptions.type, true) /
						clusterOptions.typeOffset
				) - 1;

			rangeCellOffsetFirst = clusteringOffsetFirst;
			rangeCellOffsetLast = clusteringOffsetLast;

			if (
				rangeCellOffsetFirst >= clusterOptions.columnCount ||
				rangeCellOffsetLast < 0
			) {
				// If the segment is going to be rendered offscreen don't return segment data.
				return [];
			}
		}

		// loop through all the rows in the view
		for (var row = 0; row < rowCnt; row++) {
			if (noResources) {
				createSegment(null, instance);
				instance++;
			} else {
				for (var i = 0; i < breakoutItem.length; i++) {
					if (
						(breakoutItem[i] == breakoutItems[row].name ||
							(breakoutItem[i] === '' &&
								breakoutItems[row].name === noFilterLabel) ||
							(breakoutItem[i] === undefined &&
								breakoutItems[row].name === noFilterLabel) ||
							(breakoutItem[i] === null &&
								breakoutItems[row].name === noFilterLabel)) &&
						(noBreakout ||
							!breakoutItems[row].status ||
							!breakoutItems[row].status.collapsed)
					) {
						createSegment(breakoutItem[i], instance);
						instance++;
					}
				}
			}
		}
		return segments;

		function createSegment(breakoutItem, instance) {
			var isStart;
			var isEnd;

			var segmentCellOffsetFirst;
			var segmentCellOffsetLast;

			var segmentCellFirst;
			var segmentCellLast;
			// first and last cell offset for the row
			var rowCellOffsetFirst = 0 * colCnt;
			var rowCellOffsetLast = rowCellOffsetFirst + colCnt - 1;

			// get the segment's cell offsets by constraining the range's cell offsets to the bounds of the row
			if (clusterOptions) {
				segmentCellOffsetFirst = rangeCellOffsetFirst;
				segmentCellOffsetLast = rangeCellOffsetLast;
			} else {
				segmentCellOffsetFirst = Math.max(
					rangeCellOffsetFirst,
					rowCellOffsetFirst
				);
				segmentCellOffsetLast = Math.min(
					rangeCellOffsetLast,
					rowCellOffsetLast
				);
			}

			// make sure segment's offsets are valid and in view
			if (segmentCellOffsetFirst <= segmentCellOffsetLast) {
				if (clusterOptions) {
					segmentCellFirst = {
						row: 0,
						col: Math.max(segmentCellOffsetFirst, 0),
					};
					segmentCellLast = {
						row: 0,
						col: Math.min(
							segmentCellOffsetLast,
							clusterOptions.columnCount - 1
						),
					};

					isStart = clusteringOffsetFirst >= 0;
					isEnd = clusteringOffsetLast < clusterOptions.columnCount;
				} else {
					// translate to cells
					segmentCellFirst = cellOffsetToCell(segmentCellOffsetFirst);
					segmentCellLast = cellOffsetToCell(segmentCellOffsetLast);

					// Determine if segment's first/last cell is the beginning/end of the date range.
					// We need to compare "day offset" because "cell offsets" are often ambiguous and
					// can translate to multiple days, and an edge case reveals itself when we the
					// range's first cell is hidden (we don't want isStart to be true).

					isStart =
						cellOffsetToDayOffset(segmentCellOffsetFirst) ==
						rangeDayOffsetStart;
					isEnd =
						cellOffsetToDayOffset(segmentCellOffsetLast) + 1 ==
						rangeDayOffsetEnd; // +1 for comparing exclusively
				}

				// view might be RTL, so order by leftmost column
				// cols = [ segmentCellFirst.col, segmentCellLast.col ].sort();
				cols = [segmentCellFirst.col, segmentCellLast.col]; //Removed sort because it screws up when we have large numbers

				segments.push({
					row: row,
					leftCol: cols[0],
					rightCol: cols[1],
					isStart: isStart,
					isEnd: isEnd,
					isClustered: clusterOptions
						? clusterOptions.isClustered
						: false,
					breakoutItem: breakoutItem,
					instance: instance,
				});
			}
		}
	}

	//Customized for SeedCode so we can use resource rows rather than dates / times
	function resourceAgendaGridRangeToSegments(
		start,
		end,
		isResourceView,
		resource,
		noResources
	) {
		var rowCnt = t.getRowCnt();
		var resourceDays = options.resourceDays;

		var gridTimes = options.gridTimes;

		var instance = 0;
		//
		// var minTime = moment.duration(options.minTime);
		// var maxTime = moment.duration(options.maxTime);
		// var gridStartTime = moment.duration(options.gridStartTime);
		var slotDuration = moment.duration(options.slotDuration).asMinutes();

		var colCnt = t.getColCnt();
		var resources;
		if (t.getResources) {
			resources = t.getResources(false);
		}

		var segments = []; // array of segments to return
		var cols = [];

		// column offset for given date range (how many columns from our view start date)
		var gridRangeStart;
		var rangeColOffsetStart = dateToTimeOffset(start, slotDuration);
		var rangeColOffsetEnd = dateToTimeOffset(end, slotDuration); // an exclusive value
		// var endTimeMS = +end.time();
		// if (endTimeMS && endTimeMS >= nextDayThreshold) {
		// 	rangeDayOffsetEnd++;
		// }
		rangeColOffsetEnd = Math.max(
			rangeColOffsetEnd,
			rangeColOffsetStart + 1
		);

		// first and last cell offset for the given date range Used to determine if the segment is event start or end
		// "last" implies inclusivity
		var rangeCellOffsetFirst = rangeColOffsetStart;
		var rangeCellOffsetLast = rangeColOffsetEnd - 1;

		// loop through all the rows in the view
		for (var row = 0; row < rowCnt; row++) {
			if (noResources) {
				createSegment(null, instance);
				instance++;
			} else {
				for (var i = 0; i < resource.length; i++) {
					if (resource[i] === resources[row].name) {
						createSegment(resource[i], instance);
						instance++;
					}
				}
			}
		}
		return segments;

		function createSegment(breakoutItem, instance) {
			// first and last cell offset for the row
			var rowCellOffsetFirst = 0 * colCnt;
			var rowCellOffsetLast = rowCellOffsetFirst + colCnt - 1;

			// get the segment's cell offsets by constraining the range's cell offsets to the bounds of the row
			var segmentCellOffsetFirst = Math.max(
				rangeCellOffsetFirst,
				rowCellOffsetFirst
			);
			var segmentCellOffsetLast = Math.min(
				rangeCellOffsetLast,
				rowCellOffsetLast
			);

			// make sure segment's offsets are valid and in view
			if (segmentCellOffsetFirst <= segmentCellOffsetLast) {
				// translate to cells
				var segmentCellFirst = cellOffsetToCell(segmentCellOffsetFirst);
				var segmentCellLast = cellOffsetToCell(segmentCellOffsetLast);

				// Determine if segment's first/last cell is the beginning/end of the date range.
				// We need to compare "day offset" because "cell offsets" are often ambiguous and
				// can translate to multiple days, and an edge case reveals itself when we the
				// range's first cell is hidden (we don't want isStart to be true).
				var isStart = rangeCellOffsetFirst >= 0;
				var isEnd = rangeCellOffsetLast < colCnt;

				// view might be RTL, so order by leftmost column
				// cols = [ segmentCellFirst.col, segmentCellLast.col ].sort();
				cols = [segmentCellFirst.col, segmentCellLast.col]; //Removed sort because it screws up when we have large numbers

				segments.push({
					row: row,
					leftCol: cols[0],
					rightCol: cols[1],
					isStart: isStart,
					isEnd: isEnd,
					breakoutItem: breakoutItem,
					instance: instance,
				});
			}
		}
	}

	//Customized for SeedCode
	// day offset -> cell offset
	function resourceDayOffsetToCellOffset(dayOffset, resource) {
		var resources = t.getResources(false);
		var colCnt = t.getColCnt();
		for (var i = 0; i < resources.length; i++) {
			for (var ii = 0; ii < resource.length; ii++) {
				if (resource[ii] === resources[i].name) {
					return dayOffset * colCnt + i;
				}
			}
		}
	}

	// cell offset -> cell (object with row & col keys)
	function resourceCellOffsetToCell(cellOffset, resourceDays) {
		var colCnt = t.getColCnt();

		// rtl variables. wish we could pre-populate these. but where?
		// var dis = isRTL ? -1 : 1;
		// var dit = isRTL ? colCnt - 1 : 0;
		var row = Math.floor(cellOffset / (colCnt * resourceDays));
		var col = cellOffset;
		return {
			row: row,
			col: col,
		};
	}

	function getHiddenDayOffset(dateStart, dateEnd, isExclusive) {
		var out = dateEnd.clone();
		var difference = dateEnd.diff(dateStart, 'days');
		var startDay = dateStart.day();
		var day;
		var hiddenDayCount = 0;
		//inc = inc || 1;
		for (var i = 0; i < difference; i++) {
			day = (startDay + i) % 7;
			if (isHiddenDayHash[day]) {
				hiddenDayCount++;
			}
		}
		return hiddenDayCount;
	}

	//Customized for SeedCode so we can use resources
	function resourceRangeToSegments(start, end, isResourceView, resource) {
		var rowCnt = t.getRowCnt();
		var colCnt = t.getColCnt();
		var startDay = t.start.day();

		var instance = 0;

		var resources;
		if (t.getResources) {
			resources = t.getResources(false);
		}

		var rangeDayOffsetStart;
		var rangeDayOffsetEnd;
		var hiddenDayOffset = getHiddenDayOffset(t.start, start, true);
		var hiddenDayCount = 0;
		var segments = []; // array of segments to return
		var cols = [];

		// day offset for given date range (how many days from our view start date)
		var rangeDayStart = dateToDayOffset(start);
		var rangeDayEnd = dateToDayOffset(end); // an exclusive value

		var endTimeMS = +end.time();
		if (endTimeMS && endTimeMS >= nextDayThreshold) {
			rangeDayEnd++;
		}
		rangeDayEnd = Math.max(rangeDayEnd, rangeDayStart + 1);
		for (var days = 0; days < rangeDayEnd - rangeDayStart; days++) {
			rangeDayOffsetStart = rangeDayStart + days;
			rangeDayOffsetEnd = rangeDayEnd + days;

			// Skip this if it is a hidden day.
			// if ((startDay + rangeDayOffsetStart) % 6 === 0 || (startDay + rangeDayOffsetStart) % 7 === 0) {
			// 	hiddenDayCount++;
			// 	continue;
			// }
			//We need to offset the days based on day we are on and start day and how many hidden days are in between
			if (
				isHiddenDayHash[(startDay + rangeDayOffsetStart) % 7] &&
				rangeDayOffsetStart >= 0 &&
				rangeDayOffsetStart <
					resourceDays + hiddenDayOffset + hiddenDayCount
			) {
				hiddenDayCount++;
				continue;
			}

			// loop through all the rows in the view
			for (var row = 0; row < rowCnt; row++) {
				// first and last cell offset for the row
				var rowCellOffsetFirst = row * colCnt * resourceDays;
				var rowCellOffsetLast =
					rowCellOffsetFirst + colCnt * resourceDays - 1;

				for (var i = 0; i < resources.length; i++) {
					for (var ii = 0; ii < resource.length; ii++) {
						if (resource[ii] === resources[i].name) {
							var segmentCellOffsetFirst =
								(rangeDayOffsetStart -
									hiddenDayCount -
									hiddenDayOffset) *
									colCnt +
								i;
							var segmentCellOffsetLast = segmentCellOffsetFirst;

							// make sure segment's offsets are valid and in view
							if (
								segmentCellOffsetFirst >= rowCellOffsetFirst &&
								segmentCellOffsetLast <= rowCellOffsetLast
							) {
								// translate to cells
								var segmentCellFirst = resourceCellOffsetToCell(
									segmentCellOffsetFirst,
									resourceDays
								);
								var segmentCellLast = segmentCellFirst;
								// var segmentCellLast = resourceCellOffsetToCell(segmentCellOffsetLast, resourceDays);

								// Determine if segment's first/last cell is the beginning/end of the date range.
								// We need to compare "day offset" because "cell offsets" are often ambiguous and
								// can translate to multiple days, and an edge case reveals itself when we the
								// range's first cell is hidden (we don't want isStart to be true).

								var isStart =
									rangeDayStart === rangeDayOffsetStart;
								var isEnd =
									rangeDayEnd === rangeDayOffsetStart + 1;

								// view might be RTL, so order by leftmost column
								// cols = [
								// 	segmentCellFirst.col,
								// 	segmentCellLast.col,
								// ].sort();
								cols = [
									segmentCellFirst.col,
									segmentCellLast.col,
								]; //Removed sort because it screws up when we have large numbers
								segments.push({
									row: row,
									leftCol: cols[0],
									rightCol: cols[1],
									isStart: isStart,
									isEnd: isEnd,
									breakoutItem: resource[ii],
									instance: instance,
								});
								instance++;
							}
						}
					}
				}
			}
		}

		return segments;
	}

	//Customized for SeedCode so we can use resources
	function resourceDaysRangeToSegments(start, end, isResourceView, resource) {
		var rowCnt = t.getRowCnt();
		var colCnt = t.getColCnt();
		var startDay = t.start.day();

		var instance = 0;

		var resources;
		if (t.getResources) {
			resources = t.getResources(false);
		}

		var rangeDayOffsetStart;
		var rangeDayOffsetEnd;
		var hiddenDayOffset = getHiddenDayOffset(t.start, start, true);
		var hiddenDayCount = 0;
		var segments = []; // array of segments to return
		var cols = [];

		// day offset for given date range (how many days from our view start date)
		var rangeDayStart = dateToDayOffset(start);
		var rangeDayEnd = dateToDayOffset(end); // an exclusive value

		var endTimeMS = +end.time();
		if (endTimeMS && endTimeMS >= nextDayThreshold) {
			rangeDayEnd++;
		}

		rangeDayEnd = Math.max(rangeDayEnd, rangeDayStart + 1);

		// first and last cell offset for the row
		var rowCellOffsetFirst = 0;
		var rowCellOffsetLast =
			rowCellOffsetFirst + (rowCnt + hiddenDayOffset) - 1;

		for (var days = 0; days < rangeDayEnd - rangeDayStart; days++) {
			rangeDayOffsetStart = rangeDayStart + days;
			rangeDayOffsetEnd = rangeDayEnd + days;

			if (
				isHiddenDayHash[(startDay + rangeDayOffsetStart) % 7] &&
				rangeDayOffsetStart >= 0 &&
				rangeDayOffsetStart < rowCnt + hiddenDayOffset + hiddenDayCount
			) {
				hiddenDayCount++;
				rowCellOffsetLast++;
				continue;
			}

			// loop through all the resources in the view
			for (var i = 0; i < resources.length; i++) {
				for (var ii = 0; ii < resource.length; ii++) {
					if (resource[ii] === resources[i].name) {
						// var segmentCellOffsetFirst = i;
						var segmentCellOffsetFirst = i;
						var segmentCellOffsetLast = segmentCellOffsetFirst;

						// make sure segment's offsets are valid and in view

						if (
							rangeDayOffsetStart >= rowCellOffsetFirst &&
							rangeDayOffsetStart <= rowCellOffsetLast
						) {
							// if (segmentCellOffsetFirst >= rowCellOffsetFirst && segmentCellOffsetLast <= rowCellOffsetLast) {
							// translate to cells

							var segmentCellFirst = {
								row: 0,
								col: segmentCellOffsetFirst,
							}; //resourceCellOffsetToCell(segmentCellOffsetFirst, rowCnt);
							var segmentCellLast = segmentCellFirst;
							// var segmentCellLast = resourceCellOffsetToCell(segmentCellOffsetLast, rowCnt);
							// Determine if segment's first/last cell is the beginning/end of the date range.
							// We need to compare "day offset" because "cell offsets" are often ambiguous and
							// can translate to multiple days, and an edge case reveals itself when we the
							// range's first cell is hidden (we don't want isStart to be true).
							var isStart = rangeDayStart === rangeDayOffsetStart;
							var isEnd = rangeDayEnd === rangeDayOffsetStart + 1;
							// view might be RTL, so order by leftmost column
							// cols = [
							// 	segmentCellFirst.col,
							// 	segmentCellLast.col,
							// ].sort();
							cols = [segmentCellFirst.col, segmentCellLast.col]; //Removed sort because it screws up when we have large numbers
							segments.push({
								row:
									rangeDayOffsetStart -
									hiddenDayOffset -
									hiddenDayCount,
								leftCol: cols[0],
								rightCol: cols[1],
								isStart: isStart,
								isEnd: isEnd,
								breakoutItem: resource[ii],
								instance: instance,
							});
							instance++;
						}
					}
				}
			}
		}

		return segments;
	}

	//Customized for SeedCode so we can use resource rows rather than dates / times
	function agendaDaysRangeToSegments(start, end) {
		var rowCnt = t.getRowCnt();

		var instance = 0;
		//
		// var minTime = moment.duration(options.minTime);
		// var maxTime = moment.duration(options.maxTime);
		// var gridStartTime = moment.duration(options.gridStartTime);
		var slotDuration = moment.duration(options.slotDuration).asMinutes();

		var colCnt = t.getColCnt();
		var startDay = t.start.day();

		var rangeDayOffsetStart;
		var rangeDayOffsetEnd;
		var hiddenDayOffset = getHiddenDayOffset(t.start, start, true);
		var hiddenDayCount = 0;

		var segments = []; // array of segments to return
		var cols = [];

		// day offset for given date range (how many days from our view start date)
		var rangeDayStart = dateToDayOffset(start);
		var rangeDayEnd = dateToDayOffset(end); // an exclusive value
		// var endTimeMS = +end.time();
		// if (endTimeMS && endTimeMS >= nextDayThreshold) {
		// 	rangeDayOffsetEnd++;
		// }

		rangeDayEnd = Math.max(rangeDayEnd, rangeDayStart);

		// column offset for given date range (how many columns from our view start date)
		var gridRangeStart;
		var rangeColOffsetStart = dateToTimeOffset(start, slotDuration);
		var rangeColOffsetEnd = dateToTimeOffset(end, slotDuration); // an exclusive value

		// first and last cell offset for the given date range Used to determine if the segment is event start or end
		// "last" implies inclusivity
		var rangeCellOffsetFirst;
		var rangeCellOffsetLast;

		for (var days = 0; days < rangeDayEnd - rangeDayStart; days++) {
			rangeDayOffsetStart = rangeDayStart + days;
			rangeDayOffsetEnd = rangeDayEnd + days;

			// Skip this if it is a hidden day.
			// if ((startDay + rangeDayOffsetStart) % 6 === 0 || (startDay + rangeDayOffsetStart) % 7 === 0) {
			// 	hiddenDayCount++;
			// 	continue;
			// }
			//We need to offset the days based on day we are on and start day and how many hidden days are in between
			// if (
			// 	isHiddenDayHash[(startDay + rangeDayOffsetStart) % 7] &&
			// 	rangeDayOffsetStart >= 0 &&
			// 	rangeDayOffsetStart <
			// 		resourceDays + hiddenDayOffset + hiddenDayCount
			// ) {
			// 	hiddenDayCount++;
			// 	continue;
			// }
		}
		// loop through all the rows in the view
		for (var row = 0; row < rowCnt; row++) {
			if (isHiddenDayHash[(startDay + row) % 7]) {
				hiddenDayCount++;
				rowCnt++;
				continue;
			}
			if (row >= rangeDayStart && row <= rangeDayEnd) {
				// Pass instance so we know if it is the start or not. This will also affect dateToRowTimeOffset
				rangeCellOffsetFirst = dateToRowTimeOffset(
					start,
					row,
					slotDuration
				);
				rangeCellOffsetLast =
					dateToRowTimeOffset(end, row, slotDuration) - 1; // an exclusive value
				createSegment(row, instance);
				instance++;
			}
			// if (noResources) {
			// createSegment(row, instance);
			// instance++;
			// } else {
			// 	for (var i = 0; i < resource.length; i++) {
			// 		if (resource[i] === resources[row].name) {
			// 			createSegment(resource[i], instance);
			// 			instance++;
			// 		}
			// 	}
			// }
		}
		return segments;

		function createSegment(row, instance) {
			// first and last cell offset for the row
			var rowCellOffsetFirst = row * colCnt;
			var rowCellOffsetLast = rowCellOffsetFirst + colCnt - 1;

			// get the segment's cell offsets by constraining the range's cell offsets to the bounds of the row

			var segmentCellOffsetFirst = Math.max(
				rangeCellOffsetFirst,
				rowCellOffsetFirst
			);
			var segmentCellOffsetLast = Math.min(
				rangeCellOffsetLast,
				rowCellOffsetLast
			);

			// make sure segment's offsets are valid and in view
			if (segmentCellOffsetFirst <= segmentCellOffsetLast) {
				// translate to cells
				var segmentCellFirst = cellOffsetToCell(segmentCellOffsetFirst);
				var segmentCellLast = cellOffsetToCell(segmentCellOffsetLast);

				// Determine if segment's first/last cell is the beginning/end of the date range.
				// We need to compare "day offset" because "cell offsets" are often ambiguous and
				// can translate to multiple days, and an edge case reveals itself when we the
				// range's first cell is hidden (we don't want isStart to be true).
				var isStart = rangeCellOffsetFirst >= rowCellOffsetFirst;
				var isEnd = rangeCellOffsetLast <= rowCellOffsetLast;

				// view might be RTL, so order by leftmost column
				// cols = [ segmentCellFirst.col, segmentCellLast.col ].sort();
				cols = [segmentCellFirst.col, segmentCellLast.col]; //Removed sort because it screws up when we have large numbers

				segments.push({
					row: row - hiddenDayCount,
					leftCol: cols[0],
					rightCol: cols[1],
					isStart: isStart,
					isEnd: isEnd,
					breakoutItem: null,
					instance: instance,
				});
			}
		}
	}

	function createExternalDragTimeDates(start, draggedEvent) {
		const newStart = start.clone();
		const dateStart = draggedEvent?.start;
		const dateEnd = draggedEvent?.end;
		let durationType;
		let dateDiff;
		let hasTime;

		if (
			(!draggedEvent || !dateStart || !dateEnd || draggedEvent.allDay) &&
			newStart.hasTime()
		) {
			// Default timed duration because dragged event doesn't exist
			// or changing from all day to timed
			hasTime = true;
			durationType = 'minutes';
			dateDiff = calendar.defaultTimedEventDuration;
		} else if (
			(!draggedEvent || !dateStart || !dateEnd || !draggedEvent.allDay) &&
			!newStart.hasTime()
		) {
			// Default timed duration because dragged event doesn't exist
			// or changing from timed to all day
			durationType = 'days';
			dateDiff = calendar.defaultAllDayEventDuration;
		} else if (newStart.hasTime()) {
			hasTime = true;
			durationType = 'minutes';
			dateDiff = dateEnd.diff(dateStart, durationType);
		} else {
			durationType = 'days';
			dateDiff = dateEnd.diff(dateStart, durationType);
		}
		const newEnd = newStart.clone().add(dateDiff, durationType);

		return {
			start: newStart,
			end: newEnd,
			duration: dateDiff,
			durationType: durationType,
			hasTime: hasTime,
		};
	}

	function createExternalDragDayDates(start, draggedEvent) {
		const dateStart = draggedEvent?.start;
		const dateEnd = draggedEvent?.end;
		let newStart = start.clone();
		let durationType;
		let dateDiff;
		let hasTime;

		if (!draggedEvent || !dateStart || !dateEnd) {
			// Default timed duration because dragged event doesn't exist
			// or changing from timed to all day
			durationType = 'days';
			dateDiff = calendar.defaultAllDayEventDuration;
		} else if (dateStart.hasTime()) {
			const startForTime = draggedEvent.start.clone();
			durationType = 'minutes';
			newStart = fc.moment(moment(newStart));
			newStart.hours(startForTime.hours());
			newStart.minutes(startForTime.minutes());
			hasTime = true;
			durationType = 'minutes';
			dateDiff = dateEnd.diff(dateStart, durationType);
		} else {
			durationType = 'days';
			dateDiff = dateEnd.diff(dateStart, durationType);
		}
		const newEnd = newStart.clone().add(dateDiff, durationType);

		return {
			start: newStart,
			end: newEnd,
			duration: dateDiff,
			durationType: durationType,
			hasTime: hasTime,
		};
	}
}
